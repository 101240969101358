import Container from "react-bootstrap/Container";
import "./App.css";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { Component } from "react";
import Switch from "react-switch";
import ToggleButton from "react-bootstrap/ToggleButton";
import Alert from "react-bootstrap/Alert";
import { Navbar, Spinner } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import cdkOutputObject from "./cdk-outputs.json";
import { JitsiMeeting } from "@jitsi/react-sdk";
import { Modal } from "react-bootstrap";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Info from "./Info";
import logo from "./brain.png";
import ReactGA from "react-ga4";
import {
  loggedOutMessage,
  millisToDisableToggle,
  notLoggedInMessage,
  poolClientId,
  poolId,
  trySchedulerString,
} from "./constants";
import Login from "./Login";
import Scheduler from "./Scheduler";
import UserProfile from "./UserProfile";
import Footer from "./Footer";

if (cdkOutputObject.PairBackendStack.GAProprerty) {
  ReactGA.initialize("G-JRH5MNX92D");
  ReactGA.send("pageview");
}

class App extends Component {
  constructor(props) {
    super(props);

    const soundUrl = new URL("./notificationSound.mp3", import.meta.url);
    const notificationAudio = new Audio(soundUrl);

    this.state = {
      currentDifficulty: "0",
      toggledOn: false,
      websocket: null,
      pairedId: "",
      meetingUrl: "",
      pairedStatus: "closed",
      websocketError: false,
      statusMessage: "",
      timerSeconds: 60,
      timerFunction: null,
      isToggleDisabled: false,
      isAcceptButtonDisabled: false,
      isFindNewPartnerButtonDisabled: false,
      lastToggleSwitchTime: 0,
      audio: notificationAudio,
      showResetModal: false,
      currentTab: cdkOutputObject.PairBackendStack.schedulerEnabled === "true" ? "scheduler" : "partner",
      isToggleTimeDisabled: false,
      isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
      sessionId: null,

      // cognito login stuff
      accessToken: null,
      idToken: null,
      loggedIn: false,
      loginMessage: notLoggedInMessage,

      // feature flag
      schedulerEnabled:
        cdkOutputObject.PairBackendStack.schedulerEnabled === "true",

      // tutorial modal in scheduler and user profile pages
      showTutorialModal: true,
      disableTutorialChecked: false,
    };
    this.handleToggleChange = this.handleToggleChange.bind(this);
    this.resetToggleSpinner = this.resetToggleSpinner.bind(this);
    this.updateStatusMessageWhenWaiting =
      this.updateStatusMessageWhenWaiting.bind(this);
    this.sendHeartbeat = this.sendHeartbeat.bind(this);
  }

  handleSelectTab = (key) => {
    this.setState({ currentTab: key });
  };

  showResetModal = () => {
    this.setState({
      showResetModal: true,
    });
  };

  closeResetModal = () => {
    this.setState({
      showResetModal: false,
    });
  };

  closeResetModalWithReset = () => {
    this.setState({
      showResetModal: false,
    });

    this.findNewPartner();
  };

  sendHeartbeat() {
    if (this.state.websocket) {
      var heartbeatMessage = JSON.stringify({
        action: "heartbeat",
        msg: {},
      });
      this.state.websocket.send(heartbeatMessage);
    }
  }

  // if empty string is sent, means we are just asking for permission.
  sendNotification(message) {
    if (!("Notification" in window)) {
      // Check if the browser supports notifications
    } else if (Notification.permission === "granted") {
      // Check whether notification permissions have already been granted;
      // if so, create a notification

      if (message) {
        const notification = new Notification(message);
      }
    } else if (Notification.permission !== "denied") {
      // We need to ask the user for permission
      Notification.requestPermission().then((permission) => {
        // If the user accepts, let's create a notification
        if (permission === "granted") {
          if (message) {
            const notification = new Notification(message);
          }
        }
      });
    } else if (Notification.permission === "denied") {
      if (message) {
        this.state.audio.play();
      }
    }

    // At last, if the user has denied notifications, and you
    // want to be respectful there is no need to bother them anymore.
  }

  decreaseSecondCount = () => {
    // times up, we disconnect
    if (this.state.timerSeconds <= 0) {
      console.log("sending disconnect message from timer");
      var disconnectMessage = JSON.stringify({
        action: "disconnect",
        msg: { api: "leetcode" },
      });
      this.state.websocket.send(disconnectMessage);

      clearInterval(this.state.timerFunction);

      if (this.state.pairedStatus === "matchFoundAccepted") {
        this.setState({
          toggledOn: false,
          pairedId: "",
          meetingUrl: "",
          pairedStatus: "closed",
          statusMessage:
            "Your partner declined. Press the toggle again to find a new match.",
          timerSeconds: 60,
          timerFunction: null,
          isToggleDisabled: false,
        });
        this.sendNotification(
          "Your partner declined. Press the toggle again to find a new match."
        );
      } else {
        this.setState((prevState) => ({
          toggledOn: false,
          pairedId: "",
          meetingUrl: "",
          pairedStatus: "closed",
          timerSeconds: 60,
          timerFunction: null,
          statusMessage:
            "You did not accept the match in time. Press the toggle again to find a new match.",
          isToggleDisabled: false,
        }));
        this.sendNotification(
          "You did not accept the match in time. Press the toggle again to find a new match."
        );
      }
      this.state.websocket.close();
    } else {
      this.setState((prevState) => ({
        timerSeconds: prevState.timerSeconds - 1,
      }));
    }
  };

  findNewPartner = () => {
    if (this.state.isFindNewPartnerButtonDisabled) {
      return;
    }

    this.setState({
      isFindNewPartnerButtonDisabled: true,
    });

    this.handleToggleChange(false);

    this.setState({
      isFindNewPartnerButtonDisabled: false,
    });
  };

  acceptPartner = () => {
    if (this.state.isAcceptButtonDisabled) {
      return;
    }

    this.setState({
      pairedStatus: "matchFoundAccepted",
      isAcceptButtonDisabled: true,
    });

    var acceptMessage = JSON.stringify({
      action: "accept",
      msg: { pairedId: this.state.pairedId, api: "leetcode" },
    });
    this.state.websocket.send(acceptMessage);

    this.setState({
      isAcceptButtonDisabled: false,
    });
  };

  resetToggleSpinner() {
    this.setState({
      isToggleTimeDisabled: false,
    });
  }

  updateStatusMessageWhenWaiting(sessionId, statusMessage) {
    if (
      this.state.sessionId &&
      sessionId === this.state.sessionId &&
      this.state.pairedStatus == "waiting"
    ) {
      this.setState({
        statusMessage: statusMessage,
      });
    }
  }

  resetTokensOnLoginTimeout = () => {
    this.setState({
      accessToken: null,
      idToken: null,
      loggedIn: false,
      loginMessage: loggedOutMessage,
    });
  };

  // this is only called if login succeeds
  updateTokensOnLoginSuccess = (accessToken, idToken) => {
    this.setState({
      accessToken: accessToken,
      idToken: idToken,
      loggedIn: true,
    });

    // 59 minute login timeout, give 1 minute buffer against cognito 60 minutes
    setTimeout(this.resetTokensOnLoginTimeout, 60 * 59 * 1000);
  };

  handleToggleChange(toggledOn) {
    // if toggle currently disabled or last toggle was less than 5 sec ago don't do anything
    if (this.state.isToggleDisabled || this.state.isToggleTimeDisabled) {
      return;
    }

    // before setting up the websocket, UI toggle the switch and disable it
    setTimeout(this.resetToggleSpinner, millisToDisableToggle);
    this.setState({
      toggledOn: toggledOn,
      isToggleDisabled: true,
      isToggleTimeDisabled: true,
      lastToggleSwitchTime: Date.now(),
    });

    // going from off to on, create a new websocket
    if (toggledOn) {
      this.sendNotification("");
      var websocket = new WebSocket(cdkOutputObject.PairBackendStack.apiUrl);

      // on websocket open, send connect, set paired status to waiting
      websocket.addEventListener("open", (event) => {
        var connectMessage = JSON.stringify({
          action: "connect",
          msg: {
            pairCriteria: "leetcode-" + this.state.currentDifficulty,
            api: "leetcode",
          },
        });

        // TODO is this async?
        websocket.send(connectMessage);
        const sessionId = Date.now();

        this.setState({
          toggledOn: toggledOn,
          pairedId: "",
          meetingUrl: "",
          pairedStatus: "waiting",
          websocket: websocket,
          statusMessage: "Looking for a partner...",
          isToggleDisabled: false,
          sessionId: sessionId,
        });

        // set timeouts for different status messages while waiting
        function createStatusChangeCallback(sessionId, message, time) {
          return function () {
            setTimeout(() => {
              this.updateStatusMessageWhenWaiting(sessionId, message);
            }, time);
          };
        }
        const secondMessage = `Still looking for a partner...${
          this.state.schedulerEnabled ? trySchedulerString : ""
        }`;
        const thirdMessage = `Having trouble finding a partner, still looking. Feel free to navigate to a different tab, just don't close this one.${
          this.state.schedulerEnabled ? trySchedulerString : ""
        }`;
        createStatusChangeCallback(sessionId, secondMessage, 30000).call(this);
        createStatusChangeCallback(sessionId, thirdMessage, 120000).call(this);
      });

      // on websocket close, set pairedId and meetingUrl back to empty, paired status is closed, toggle off
      // this code should only be meaningful if the websocket is suddenly/unexpectedly cut off.
      // so we only execute the code if we aren't already in closed state.
      // if session is started, we are chattng and don't want to touch anything.
      websocket.addEventListener("close", (event) => {
        if (
          this.state.pairedStatus !== "closed" &&
          this.state.pairedStatus !== "sessionStarted"
        ) {
          // stop the timer
          clearInterval(this.state.timerFunction);

          this.setState({
            toggledOn: false,
            pairedId: "",
            meetingUrl: "",
            pairedStatus: "closed",
            statusMessage:
              "Connection closed. Press the toggle again to find a new partner.",
            timerSeconds: 60,
            timerFunction: null,
            isToggleDisabled: false,
          });
        }
      });

      // on websocket error, set pairedId and meetingUrl back to empty, paired status is closed, toggle off, set error message
      websocket.addEventListener("error", (event) => {
        // stop the timer
        clearInterval(this.state.timerFunction);

        this.setState({
          toggledOn: false,
          pairedId: "",
          meetingUrl: "",
          pairedStatus: "closed",
          websocketError: true,
          timerSeconds: 60,
          statusMessage: "Error encountered in websocket. Try again later.",
          timerFunction: null,
          isToggleDisabled: false,
        });
        this.sendNotification(
          "Error encountered in websocket. Try again later."
        );
      });

      // websocket message received
      websocket.addEventListener("message", (event) => {
        var data = JSON.parse(event.data);
        if (data.status === "dummy message") {
          console.log("received dummy message");
        } else if (data.status === "matchFound") {
          // start the timer
          var timerFunction = setInterval(this.decreaseSecondCount, 1000);

          this.setState({
            pairedId: data.pairedId,
            pairedStatus: "matchFound",
            timerSeconds: 60,
            timerFunction: timerFunction,
            isToggleDisabled: false,
          });

          this.sendNotification("Match found, you have 1 minute to accept.");
        } else if (data.status === "partnerDeclined") {
          // stop the timer
          clearInterval(this.state.timerFunction);

          // I already accepted the match, or I'm also waiting but not out of time yet
          // if the timerFunction is null, means the -1 second handler already set it to null
          // (we are currently displaying I ran out of time message) so we don't want to change the error message here
          if (
            this.state.pairedStatus === "matchFoundAccepted" ||
            (this.state.pairedStatus === "matchFound" &&
              this.state.timerFunction)
          ) {
            var disconnectMessage = JSON.stringify({
              action: "disconnect",
              msg: { api: "leetcode" },
            });
            this.state.websocket.send(disconnectMessage);

            this.setState({
              toggledOn: false,
              pairedId: "",
              meetingUrl: "",
              pairedStatus: "closed",
              statusMessage:
                "Your partner declined. Press the toggle again to find a new match.",
              timerSeconds: 60,
              timerFunction: null,
              isToggleDisabled: false,
            });
            this.state.websocket.close();
            this.sendNotification(
              "Your partner declined. Press the toggle again to find a new match."
            );
          }
        } else if (data.status === "sessionStarted") {
          // stop the timer
          clearInterval(this.state.timerFunction);

          this.setState({
            pairedId: data.pairedId,
            pairedStatus: "sessionStarted",
            meetingUrl: data.meetingUrl,
            timerFunction: null,
            isToggleDisabled: false,
          });

          this.sendNotification("Session started");

          //close the websocket since session started
          this.state.websocket.close();
        } else {

          clearInterval(this.state.timerFunction);
          this.setState({
            toggledOn: false,
            pairedId: "",
            meetingUrl: "",
            pairedStatus: "closed",
            timerSeconds: 60,
            timerFunction: null,
            websocketError: true,
            statusMessage: "Error encountered in websocket. Try again later.",
            isToggleDisabled: false,
          });
          this.sendNotification(
            "Error encountered in websocket. Try again later."
          );
        }
      });
    }

    // going from on to off, disconnect
    else {
      console.log("sending disconnect message from toggle");
      var disconnectMessage = JSON.stringify({
        action: "disconnect",
        msg: { api: "leetcode" },
      });
      clearInterval(this.state.timerFunction);
      this.state.websocket.send(disconnectMessage);
      this.setState({
        toggledOn: false,
        pairedId: "",
        meetingUrl: "",
        pairedStatus: "closed",
        timerSeconds: 60,
        timerFunction: null,
        statusMessage:
          "Connection closed. Press the toggle again to find a new match.",
        isToggleDisabled: false,
        sessionId: null,
      });
      this.state.websocket.close();
    }
  }

  handleDifficultyChange = (event) => {
    this.setState({ currentDifficulty: event.target.value });
  };

  getLastItem = (thePath) => thePath.substring(thePath.lastIndexOf("/") + 1);

  windowCloseEventListener(event) {
    if (this.state.pairedStatus !== "closed") {
      var disconnectMessage = JSON.stringify({
        action: "disconnect",
        msg: { api: "leetcode" },
      });
      this.state.websocket.send(disconnectMessage);
      this.state.websocket.close();
    }
  }

  onDisableTutorialChecked = (e) => {
    this.setState({ disableTutorialChecked: e.target.checked });

    // update local storage
    localStorage.setItem(
      "showTutorialModal",
      JSON.stringify(!e.target.checked)
    );
  };

  hideTutorialModalFunction = () => {
    this.setState({ showTutorialModal: false });
  };

  componentDidMount() {
    window.addEventListener("beforeunload", (e) =>
      this.windowCloseEventListener(e)
    );

    // heartbeat for websocket
    setInterval(this.sendHeartbeat, 300000);

    // load anything necessary here
    const showTutorialModel = localStorage.getItem("showTutorialModal");
    this.setState({
      showTutorialModal: showTutorialModel
        ? JSON.parse(showTutorialModel)
        : true,
    });
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", (e) =>
      this.windowCloseEventListener(e)
    );
  }

  render() {
    const difficulties = [
      { name: "Any", value: "0" },
      { name: "Beginner (0-2 years)", value: "1" },
      { name: "Intermediate (2-5 years)", value: "2" },
      { name: "Advanced (5+ years)", value: "3" },
    ];

    var isMobileAlertString = this.state.schedulerEnabled
      ? "We've detected you are on a mobile device. The 'On-demand finder' page doesn't work properly on mobile. Please try out the Scheduler page, or use a laptop/desktop."
      : "We've detected you are on a mobile device. The 'On-demand finder' page doesn't work properly on mobile. Please use a laptop/desktop.";

    var isMobileAlert = (
      <Alert key="danger" variant="danger" className="top-header">
        <Row>
          <Col className="verticalAlignCol">
            <div className="leftOfTwo">
              {isMobileAlertString}
            </div>
          </Col>
        </Row>
      </Alert>
    );

    var statusBar;

    if (this.state.pairedStatus === "closed") {
      if (this.state.websocketError) {
        statusBar = (
          <Alert key="danger" variant="danger" className="top-header">
            <Row>
              <Col className="verticalAlignCol">
                <div className="leftOfTwo">{this.state.statusMessage} </div>
              </Col>
            </Row>
          </Alert>
        );
      } else {
        statusBar =
          this.state.statusMessage !== "" ? (
            <Alert key="primary" variant="primary" className="top-header">
              <Row>
                <Col className="verticalAlignCol">
                  <div className="leftOfTwo">{this.state.statusMessage} </div>
                </Col>
              </Row>
            </Alert>
          ) : null;
      }
    } else if (this.state.pairedStatus === "waiting") {
      statusBar = (
        <Alert key="primary" variant="primary" className="top-header">
          <Row>
            <Col className="verticalAlignCol">
              <div className="leftOfTwo">{this.state.statusMessage}</div>
            </Col>

            <Col className="verticalAlignCol">
              <Spinner className="rightOfTwo" animation="border" />
            </Col>
          </Row>
        </Alert>
      );
    } else if (this.state.pairedStatus === "matchFound") {
      statusBar = (
        <Alert key="primary" variant="primary" className="top-header">
          <Row>
            <Col className="verticalAlignCol">
              <div className="leftOfTwo">
                Partner found. You have {this.state.timerSeconds}s to accept.
              </div>
            </Col>

            <Col className="verticalAlignCol">
              <Button
                className="rightOfTwo"
                variant="primary"
                onClick={this.acceptPartner}
                disabled={this.state.isAcceptButtonDisabled}
              >
                Accept
              </Button>
            </Col>
          </Row>
        </Alert>
      );
    } else if (this.state.pairedStatus === "matchFoundAccepted") {
      statusBar = (
        <Alert key="primary" variant="primary" className="top-header">
          <Row>
            <Col className="verticalAlignCol">
              <div className="leftOfTwo">
                Waiting for partner to accept: {this.state.timerSeconds}s left.
              </div>
            </Col>

            <Col className="verticalAlignCol">
              <Spinner className="rightOfTwo" animation="border" />
            </Col>
          </Row>
        </Alert>
      );
    } else if (this.state.pairedStatus === "sessionStarted") {
      statusBar = (
        <>
          <Alert key="primary" variant="primary" className="top-header">
            <Row>
              <Col className="verticalAlignCol">
                <div>
                  Session started. If your partner does not connect within a few
                  minutes, please click the "End current session" button.
                </div>
              </Col>
            </Row>
          </Alert>

          <JitsiMeeting
            className="top-header"
            roomName={this.getLastItem(this.state.meetingUrl)}
            getIFrameRef={(node) => (node.style.height = "800px")}
          />

          <Button
            className="top-header"
            variant="primary"
            onClick={this.showResetModal}
            disabled={this.state.isFindNewPartnerButtonDisabled}
          >
            End current session
          </Button>

          <Modal show={this.state.showResetModal} onHide={this.closeResetModal}>
            <Modal.Header closeButton>
              <Modal.Title>Are you sure?</Modal.Title>
            </Modal.Header>
            <Modal.Footer>
              <Button variant="primary" onClick={this.closeResetModalWithReset}>
                Yes, end the session
              </Button>
              <Button variant="secondary" onClick={this.closeResetModal}>
                No
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      );
    }

    var toggle = (
      <label htmlFor="small-radius-switch">
        <Switch
          checked={this.state.toggledOn}
          onChange={this.handleToggleChange}
          disabled={
            this.state.isToggleDisabled ||
            this.state.isToggleTimeDisabled ||
            this.state.pairedStatus === "sessionStarted" ||
            this.state.isMobile
          }
          handleDiameter={28}
          offColor="#0d6efd"
          onColor="#dc3545"
          offHandleColor="#fff"
          onHandleColor="#fff"
          height={40}
          width={78}
          borderRadius={6}
          uncheckedIcon={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 15,
                color: "#fff",
                paddingRight: 2,
              }}
            >
              Off
            </div>
          }
          checkedIcon={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 15,
                color: "#fff",
                paddingRight: 2,
              }}
            >
              On
            </div>
          }
          className="react-switch"
          id="small-radius-switch"
        />
      </label>
    );

    return (
      <>
        <Container fluid>
          <Navbar bg="white" className="titleNavbar justify-content-center">
            <img src={logo} width="30" height="30" className="logo" />
            <Navbar.Brand className="titleText">
              Pair Programming Finder
            </Navbar.Brand>
          </Navbar>
          <Tabs
            id="rootTab"
            activeKey={this.state.currentTab}
            onSelect={this.handleSelectTab}
            className="mb-3"
          >
            {this.state.schedulerEnabled ? (
              <Tab eventKey="scheduler" title="Scheduler" className="ms-auto">
                <Login
                  loginMessage={this.state.loginMessage}
                  poolId={cdkOutputObject.PairBackendStack.userPoolId}
                  poolClientId={
                    cdkOutputObject.PairBackendStack.userPoolClientId
                  }
                  updateTokensOnLoginSuccess={this.updateTokensOnLoginSuccess}
                  idToken={this.state.idToken}
                  accessToken={this.state.accessToken}
                  key={this.state.idToken}
                  loggedIn={this.state.loggedIn}
                >
                  <Scheduler
                    isVisible={this.state.currentTab === "scheduler"}
                    showTutorialModal={this.state.showTutorialModal}
                    hideTutorialModalFunction={this.hideTutorialModalFunction}
                    onDisableTutorialChecked={this.onDisableTutorialChecked}
                  />
                </Login>
              </Tab>
            ) : null}
            {this.state.schedulerEnabled ? (
              <Tab
                eventKey="userProfile"
                title="User Profile"
                className="ms-auto"
              >
                <Login
                  loginMessage={this.state.loginMessage}
                  poolId={cdkOutputObject.PairBackendStack.userPoolId}
                  poolClientId={
                    cdkOutputObject.PairBackendStack.userPoolClientId
                  }
                  updateTokensOnLoginSuccess={this.updateTokensOnLoginSuccess}
                  idToken={this.state.idToken}
                  accessToken={this.state.accessToken}
                  key={this.state.idToken}
                  loggedIn={this.state.loggedIn}
                >
                  <UserProfile />
                </Login>
              </Tab>
            ) : null}
            <Tab eventKey="partner" title="On-demand Finder" className="ms-auto">
              <div>
                <div class="text-center">
                  {this.state.isMobile ? isMobileAlert : null}
                  {this.state.pairedStatus === "sessionStarted" ? null : (
                    <>
                      <h6 className="top-header">
                        {" "}
                        Please select your programming level:{" "}
                      </h6>
                      <ButtonGroup className="regularItem">
                        {difficulties.map((difficulty, idx) => (
                          <ToggleButton
                            key={idx}
                            id={`difficulty-${idx}`}
                            type="radio"
                            disabled={
                              this.state.pairedStatus !== "closed" ||
                              this.state.isToggleDisabled ||
                              this.state.isToggleTimeDisabled ||
                              this.state.pairedStatus === "sessionStarted" ||
                              this.state.isMobile
                            }
                            variant="outline-primary"
                            name="difficulty"
                            value={difficulty.value}
                            checked={
                              this.state.currentDifficulty === difficulty.value
                            }
                            onChange={(e) => this.handleDifficultyChange(e)}
                          >
                            {difficulty.name}
                          </ToggleButton>
                        ))}
                      </ButtonGroup>

                      <div class="text-center">
                        <h6 className="top-header">
                          {" "}
                          Toggle the switch to find a partner:{" "}
                        </h6>
                        {this.state.isToggleDisabled ||
                        this.state.isToggleTimeDisabled ? (
                          <Row xs="auto">
                            <Col className="rightOfTwo">{toggle}</Col>

                            <Col className="leftOfTwo">
                              <Spinner animation="border" />
                            </Col>
                          </Row>
                        ) : (
                          toggle
                        )}
                      </div>
                    </>
                  )}
                  {statusBar}
                </div>
              </div>
            </Tab>
            <Tab eventKey="info" title="Information" className="ms-auto">
              <Info />
            </Tab>
          </Tabs>
          <Footer />
        </Container>
      </>
    );
  }
}

export default App;
