import React, { Component } from "react";
import { Container, Modal, Spinner } from "react-bootstrap";
import { Calendar, Views, luxonLocalizer } from "react-big-calendar";
import Button from "react-bootstrap/Button";
import { DateTime, Settings } from "luxon";
import SchedulerModal from "./SchedulerModal";
import RequestJoinModal from "./RequestJoinModal";
import ViewRequestsModal from "./ViewRequestsModal";
import {
  acceptRequestFailureText,
  acceptRequestSucceededReloadingCalendarDataText,
  acceptRequestSuccessText,
  confirmedEventColor,
  deleteEventFailedText,
  deleteEventSuccessText,
  deleteSucceededReloadingCalendarDataText,
  handleRequestStrings,
  loadingCalendarDataText,
  millisPerHour,
  pendingEventColor,
  refreshAllDataFailedText,
  refreshAllDataSuccessText,
  selfAlertText,
  sendJoinRequestFailedText,
  sendJoinRequestSucceededReloadingCalendarDataText,
  sendJoinRequestSuccessText,
  submitSelfEventFailedText,
  submitSelfEventSuccessText,
  submitSucceededReloadingCalendarDataText,
  testAllEvents,
  worldAlertText,
} from "./constants";
import ToggleButton from "react-bootstrap/ToggleButton";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Alert from "react-bootstrap/Alert";
import axios from "axios";
import cdkOutputObject from "./cdk-outputs.json";
import SchedulerGroupEventModal from "./SchedulerGroupEventModal";
import UserProfile from "./UserProfile";
import ConfirmEventDeletionModal from "./ConfirmEventDeletionModal";
import TutorialModal from "./TutorialModal";

// all calendar text will be in english
Settings.defaultLocale = "en";

class Scheduler extends Component {
  constructor(props) {
    super(props);

    this.state = {
      worldEvents: [],
      worldGroupEvents: [],
      myEvents: [],
      eventsISentPairsTo: [],
      confirmedEvents: [],
      confirmedEventKeys: [],
      showModal: false,
      whichCalendar: "myCalendar",
      alertText: selfAlertText,
      inNetworkCall: false,
      networkErrorSelfEventModal: false,
      alertType: "primary",

      // create self event modal state
      title: "",
      programmingLanguages: [],
      extraDetails: "",
      formValidated: false,
      agenda: "",
      preferredExperienceLevel: "0",
      startTime: "",
      endTime: "",
      modalType: "createEvent",
      eventId: "",
      userId: "",
      pairInfo: null,
      eventStatus: "",
      meetingId: null,

      // submit join request modal
      showMessageModal: false,
      message: "",

      // accept request modal
      showRequestsModal: false,
      requesterId: null, // to distinguish whos pair request to accept
      eventTitle: "",

      // event group modal
      showEventGroupModal: false,
      hourSelected: null,

      // user profile modal
      showUserProfileModal: false,
      userProfileOpenInRequests: false,

      // confirm deletion modal
      showDeleteEventConfirmationModal: false,
    };
  }

  handleShowWorldCalendar = (event) => {
    this.setState({
      whichCalendar: "worldCalendar",
      alertText: worldAlertText,
      alertType: "primary",
    });
  };

  handleShowMyCalendar = (event) => {
    this.setState({
      whichCalendar: "myCalendar",
      alertText: selfAlertText,
      alertType: "primary",
    });
  };

  viewRequests = (event) => {
    // open the view requests modal
    this.setState({ showRequestsModal: true, showModal: false });
  };

  showUserProfileModal = (userId) => (event) => {
    if (userId) {
      this.setState({
        showUserProfileModal: true,
        showRequestsModal: false,
        userId: userId,
        userProfileOpenInRequests: true,
      });
    } else {
      this.setState({ showUserProfileModal: true, showModal: false });
    }
  };

  hideUserProfileModal = (event) => {
    if (this.state.userProfileOpenInRequests) {
      this.setState({
        showUserProfileModal: false,
        showRequestsModal: true,
        userProfileOpenInRequests: false,
      });
    } else {
      this.setState({ showUserProfileModal: false, showModal: true });
    }
  };

  handleRequest = (requestType) => async (event) => {
    event.preventDefault();
    this.setState({ inNetworkCall: true, alertType: "primary" });
    // construct the json object and submit it to api
    const formObject = {
      eventId: this.state.eventId,
      requesterId: this.state.requesterId,
    };

    try {
      await axios.post(
        cdkOutputObject.PairBackendStack.schedulerUrl +
          `schedulerPairingEvent/${requestType}`,
        JSON.stringify(formObject),
        { headers: { Authorization: `Bearer ${this.props.idToken}` } }
      );

      this.setState({
        alertText: handleRequestStrings[requestType].successReloading,
        alertType: "primary",
      });

      this.fetchScheduleData();

      this.setState({
        alertText: handleRequestStrings[requestType].success,
        alertType: "primary",
      });
    } catch (err) {
      console.log(err);
      this.setState({
        networkErrorSelfEventModal: true,
        alertText: handleRequestStrings[requestType].failure,
        alertType: "danger",
        inNetworkCall: false,
      });
    }
  };

  hideMessageModalFunction = (event) => {
    this.setState({ showMessageModal: false, showModal: true });
  };

  hideRequestsModalFunction = (event) => {
    this.setState({ showRequestsModal: false, showModal: true });
  };

  hideDeleteConfirmationModal = (event) => {
    this.setState({ showDeleteEventConfirmationModal: false, showModal: true });
  };

  sendToDeleteConfirmationModal = (event) => {
    this.setState({ showDeleteEventConfirmationModal: true, showModal: false });
  };

  deleteEvent = async (event) => {
    this.setState({ inNetworkCall: true, alertType: "primary" });
    // construct the json object and submit it to api
    const formObject = {
      eventId: this.state.eventId,
    };

    try {
      await axios.patch(
        cdkOutputObject.PairBackendStack.schedulerUrl + "schedulerEvent",
        JSON.stringify(formObject),
        { headers: { Authorization: `Bearer ${this.props.idToken}` } }
      );

      this.setState({
        alertText: deleteSucceededReloadingCalendarDataText,
        alertType: "primary",
      });

      this.fetchScheduleData();

      this.setState({
        alertText: deleteEventSuccessText,
        alertType: "primary",
        showModal: false,
        showDeleteEventConfirmationModal: false,
        title: "",
        programmingLanguages: [],
        eventStatus: "",
        extraDetails: "",
        formValidated: false,
        inNetworkCall: false,
        agenda: "",
        preferredExperienceLevel: "0",
        pairInfo: null,
        startTime: "",
        endTime: "",
        modalType: "createEvent",
        eventId: "",
        meetingId: null,
        userId: "",
      });
    } catch (err) {
      console.log(err);
      this.setState({
        networkErrorSelfEventModal: true,
        alertText: deleteEventFailedText,
        alertType: "danger",
        inNetworkCall: false,
      });
    }
  };

  requestJoinEvent = (event) => {
    this.setState({ showMessageModal: true, showModal: false });
  };

  submitJoinRequest = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === true) {
      this.setState({ inNetworkCall: true, alertType: "primary" });
      // construct the json object and submit it to api
      const formObject = {
        eventId: this.state.eventId,
        message: this.state.message,
      };

      try {
        await axios.post(
          cdkOutputObject.PairBackendStack.schedulerUrl +
            "schedulerPairingEvent/newPairRequest",
          JSON.stringify(formObject),
          { headers: { Authorization: `Bearer ${this.props.idToken}` } }
        );

        this.setState({
          alertText: sendJoinRequestSucceededReloadingCalendarDataText,
          alertType: "primary",
        });

        this.fetchScheduleData();

        this.setState({
          alertText: sendJoinRequestSuccessText,
          alertType: "primary",
        });

        this.handleHideModal();
      } catch (err) {
        console.log(err);
        this.setState({
          networkErrorSelfEventModal: true,
          alertText: sendJoinRequestFailedText,
          alertType: "danger",
          inNetworkCall: false,
        });
      }
    } else {
      this.setState({
        formValidated: true,
      });
    }
  };

  onAccordionSelect = (accordionEventKey, accordionEvent) => {
    this.setState({ requesterId: accordionEventKey });
  };

  handleSubmitSelfScheduleItem = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === true) {
      this.setState({ inNetworkCall: true, alertType: "primary" });
      // construct the json object and submit it to api
      const formObject = {
        title: this.state.title,
        programmingLanguages: this.state.programmingLanguages,
        extraDetails: this.state.extraDetails,
        agenda: this.state.agenda,
        preferredExperienceLevel: this.state.preferredExperienceLevel,
        startTime: this.state.startTime,
        endTime: this.state.endTime,
      };

      try {
        await axios.post(
          cdkOutputObject.PairBackendStack.schedulerUrl + "schedulerEvent",
          JSON.stringify(formObject),
          { headers: { Authorization: `Bearer ${this.props.idToken}` } }
        );

        this.setState({
          alertText: submitSucceededReloadingCalendarDataText,
          alertType: "primary",
        });

        this.fetchScheduleData();

        this.setState({
          alertText: submitSelfEventSuccessText,
          alertType: "primary",
        });

        this.handleHideModal();
      } catch (err) {
        console.log(err);
        this.setState({
          networkErrorSelfEventModal: true,
          alertText: submitSelfEventFailedText,
          alertType: "danger",
          inNetworkCall: false,
        });
      }
    } else {
      this.setState({
        formValidated: true,
      });
    }
  };

  isOverLappingWithEvents(event, events) {
    for (let i = 0; i < events.length; i++) {
      if (
        (event.start >= events[i].start && event.start < events[i].end) ||
        (event.end > events[i].start && event.end <= events[i].end) ||
        (event.start <= events[i].start && event.end >= events[i].end)
      ) {
        return true;
      }
    }
    return false;
  }

  handleSelectSlot = ({ start, end, action }) => {
    const startTimestamp = start.getTime();
    if (startTimestamp < Date.now()) {
      window.alert("Events cannot be created in the past.");
      return;
    } else if (startTimestamp < 24 * millisPerHour + Date.now()) {
      window.alert("Events must be created at least 24 hours in advance.");
      return;
    }

    if (action === "select" && this.state.whichCalendar === "myCalendar") {
      let event = { start: start, end: end };
      // will be duplicates between confirmedEvents and myEvents, not ideal
      if (
        !this.isOverLappingWithEvents(event, [
          ...this.state.confirmedEvents,
          ...this.state.myEvents,
        ])
      ) {
        this.setState({
          showModal: true,
          modalType: "createEvent",
          startTime: start,
          endTime: end,
        });

        /*
      this.setState((prevState) => ({
        events: [...prevState.events, { start, end, title }],
      }));
      */
      } else {
        window.alert("Creating overlapping events is not allowed.");
      }
    }
  };

  handleHideModal = () => {
    this.setState((prevState) => ({
      showEventGroupModal:
        prevState.whichCalendar === "worldCalendar" ? true : false,
      showMessageModal: false,
      showModal: false,
      title: "",
      programmingLanguages: [],
      eventStatus: "",
      extraDetails: "",
      formValidated: false,
      agenda: "",
      modalType: "createEvent",
      preferredExperienceLevel: "0",
      pairInfo: null,
      networkErrorSelfEventModal: false,
      startTime: "",
      endTime: "",
      eventId: "",
      meetingId: "",
      userId: "",
      message: "",
      eventTitle: "",
    }));
  };

  updateEventInfoForSingleEvent = (event) => {
    this.setState((prevState) => ({
      // assuming here that only world events on world calendar, and my events on my calendar
      modalType: event.modalType,
      title: event.title,
      programmingLanguages: event.programmingLanguages,
      eventStatus: event.activeStatus,
      extraDetails: event.extraDetails,
      agenda: event.agenda,
      eventId: event.eventId,
      userId: event.userId,
      meetingId: event.meetingId,
      eventTitle: event.title,
      preferredExperienceLevel: event.preferredExperienceLevel,
      pairInfo: "pairInfo" in event ? event.pairInfo : null,
      startTime: event.start,
      endTime: event.end,
    }));
  };

  updateEventInfoForGroupEvent = (event) => {
    this.setState({
      groupOfEventsEvent: event,
    });
  };

  handleSelectEvent = (event) => {
    if (this.state.whichCalendar === "myCalendar") {
      this.updateEventInfoForSingleEvent(event);
      this.setState({ showModal: true });
    } else if (this.state.whichCalendar === "worldCalendar") {
      this.updateEventInfoForGroupEvent(event); // the passed argument "event" is the group event obj
      this.setState({ showEventGroupModal: true });
    }
  };

  /* create self event modal handlers start */
  handleTitleChange = (event) => {
    this.setState({ title: event.target.value });
  };

  handleExtraDetailsChange = (event) => {
    this.setState({ extraDetails: event.target.value });
  };

  handleMessageChange = (event) => {
    this.setState({ message: event.target.value });
  };

  handleAgendaChange = (event) => {
    this.setState({ agenda: event.target.value });
  };

  handlePreferredExperienceLevelChange = (event) => {
    this.setState({ preferredExperienceLevel: event.target.value });
  };

  handleProgrammingLanguageChange = (selectedOptionsFromSelect) => {
    this.setState({
      programmingLanguages: selectedOptionsFromSelect.map((elem) => {
        return elem.value;
      }),
    });
  };

  handleRefresh = async (event) => {
    this.fetchScheduleData();
  };

  handleHideEventGroupModal = (event) => {
    this.setState({ showEventGroupModal: false });
  };

  seeEventDetailsFromGroupModal = (event) => {
    const id = event.target.id;
    this.updateEventInfoForSingleEvent(
      this.state.groupOfEventsEvent.eventsThisHour[id]
    );
    this.setState({ showModal: true, showEventGroupModal: false });
  };
  /* create modal handlers end */

  getHoursBetweenTwoDates(date1, date2) {
    var validHours = [];
    const date2Time = date2.getTime();
    var nextTime = Math.floor(date1.getTime() / millisPerHour) * millisPerHour;

    while (nextTime < date2Time) {
      validHours.push(nextTime);

      nextTime += millisPerHour;
    }

    return validHours;
  }

  async fetchScheduleData() {
    this.setState({ inNetworkCall: true, alertType: "primary" });

    try {
      // returns a dictionary of all active events (key) to event data (value), without any pair data
      const worldDataCachedResponse = await axios.get(
        cdkOutputObject.PairBackendStack.schedulerUrl + "schedulerEvent/all",
        { headers: { Authorization: `Bearer ${this.props.idToken}` } }
      );
      var worldEventDictionary = worldDataCachedResponse["data"];

      // returns a dictionary of all my events (active or not), with pair data
      const myData = await axios.get(
        cdkOutputObject.PairBackendStack.schedulerUrl + "schedulerEvent",
        { headers: { Authorization: `Bearer ${this.props.idToken}` } }
      );
      var myEventDictionary = myData["data"]["myEventData"];
      var pairsISentDictionary = myData["data"]["pairsISentData"];
      var eventsISentPairsToDictionary = {};
      var confirmedEventsDictionary = {};

      // remove my keys from world dictionary, because my events shouldn't be shown on global calendar
      Object.keys(myEventDictionary).forEach(
        (key) => delete worldEventDictionary[key]
      );

      Object.keys(worldEventDictionary).forEach((key) => {
        // create date objects from date strings
        worldEventDictionary[key]["start"] = new Date(
          worldEventDictionary[key]["startTime"]
        );
        worldEventDictionary[key]["end"] = new Date(
          worldEventDictionary[key]["endTime"]
        );

        // set modal type
        worldEventDictionary[key]["modalType"] = "viewWorldEvent";

        // add my pair data if I requested to join this event
        if (key in pairsISentDictionary) {
          worldEventDictionary[key]["pairInfo"] = pairsISentDictionary[key];

          if (
            worldEventDictionary[key].pairInfo[0].pairStatus === "PENDING" ||
            worldEventDictionary[key].pairInfo[0].pairStatus ===
              "OVERLAP_DISABLED"
          ) {
            worldEventDictionary[key].title += " (pending)";
          }

          // and also add events I sent a pair to to my event list (deep copy)
          eventsISentPairsToDictionary[key] = {};
          eventsISentPairsToDictionary[key] = JSON.parse(
            JSON.stringify(worldEventDictionary[key])
          );
        }

        // if the world event status is overlap disabled, dont show it on the world calendar
        // we do however still want it to be visible on my calendar if i previously signed up before it got into overlap disabled

        if (worldEventDictionary[key].activeStatus === "OVERLAP_DISABLED") {
          delete worldEventDictionary[key];
        }
      });

      // need to do this because json parsing issues with dates
      Object.keys(eventsISentPairsToDictionary).forEach((key) => {
        // create date objects from date strings
        eventsISentPairsToDictionary[key]["start"] = new Date(
          eventsISentPairsToDictionary[key]["startTime"]
        );
        eventsISentPairsToDictionary[key]["end"] = new Date(
          eventsISentPairsToDictionary[key]["endTime"]
        );

        if (
          eventsISentPairsToDictionary[key]["pairInfo"][0]["pairStatus"] ===
          "ACCEPTED"
        ) {
          confirmedEventsDictionary[key] = eventsISentPairsToDictionary[key];
          eventsISentPairsToDictionary[key]["meetingId"] =
            eventsISentPairsToDictionary[key]["pairInfo"][0]["meetingId"];
        }
      });

      Object.keys(myEventDictionary).forEach((key) => {
        // create date objects from date strings
        myEventDictionary[key]["start"] = new Date(
          myEventDictionary[key]["startTime"]
        );
        myEventDictionary[key]["end"] = new Date(
          myEventDictionary[key]["endTime"]
        );

        if ("pairInfo" in myEventDictionary[key]) {
          var pendingRequestCount = 0;
          myEventDictionary[key].pairInfo.forEach((pair) => {
            if (pair.pairStatus === "PENDING") {
              pendingRequestCount += 1;
            } else if (pair.pairStatus === "ACCEPTED") {
              if (!(key in confirmedEventsDictionary)) {
                confirmedEventsDictionary[key] = myEventDictionary[key];
                confirmedEventsDictionary[key]["modalType"] = "viewSelfEvent";
              }
            }
          });
          if (pendingRequestCount > 0) {
            if (pendingRequestCount == 1) {
              myEventDictionary[
                key
              ].title += ` (${pendingRequestCount} request pending)`;
            } else {
              myEventDictionary[
                key
              ].title += ` (${pendingRequestCount} requests pending)`;
            }
          }
        }

        // set modal type
        myEventDictionary[key]["modalType"] = "viewSelfEvent";
      });

      // now populate worldEventTimeDict
      var worldEventTimeDict = {};
      Object.keys(worldEventDictionary).forEach((key) => {
        var event = worldEventDictionary[key];
        var hoursForKeyList = this.getHoursBetweenTwoDates(
          event["start"],
          event["end"]
        );
        hoursForKeyList.forEach((hour) => {
          if (hour in worldEventTimeDict) {
            worldEventTimeDict[hour].eventsThisHour.push(event);
          } else {
            var groupEventStartDate = new Date(hour);
            var groupEventEndDate = new Date(hour + millisPerHour);
            worldEventTimeDict[hour] = {
              start: groupEventStartDate,
              end: groupEventEndDate,
              eventsThisHour: [event],
              modalTitle: `Events from ${groupEventStartDate.getHours()}:00 to ${groupEventEndDate.getHours()}:00`,
            };
          }
        });
      });

      // set the title of the world group events, and sort eventsThisHour by start date
      Object.keys(worldEventTimeDict).forEach((key) => {
        worldEventTimeDict[
          key
        ].title = `${worldEventTimeDict[key].eventsThisHour.length}`;
        worldEventTimeDict[key].eventsThisHour.sort((event1, event2) => {
          return event1.start < event2.start;
        });
      });

      this.setState(
        (prevState) => ({
          worldGroupEvents: Object.values(worldEventTimeDict),
          worldEvents: Object.values(worldEventDictionary),
          myEvents: Object.values(myEventDictionary),
          eventsISentPairsTo: Object.values(eventsISentPairsToDictionary),
          confirmedEvents: Object.values(confirmedEventsDictionary),
          confirmedEventKeys: Object.keys(confirmedEventsDictionary),
          inNetworkCall: false,
        }),
        () => {
          // refresh the currently shown event data
          if (this.state.eventId) {
            if (this.state.eventId in worldEventDictionary) {
              this.updateEventInfoForSingleEvent(
                // does this cover eventsISentPairsTo case? TODO
                worldEventDictionary[this.state.eventId]
              );
            } else if (this.state.eventId in myEventDictionary) {
              this.updateEventInfoForSingleEvent(
                myEventDictionary[this.state.eventId]
              );
            }
          }

          if (this.state.groupOfEventsEvent) {
            this.updateEventInfoForGroupEvent(
              worldEventTimeDict[this.state.groupOfEventsEvent.start.getTime()]
            );
          }

          this.setState({
            alertText: refreshAllDataSuccessText,
            alertType: "primary",
          });
        }
      );
    } catch (err) {
      console.log(err);
      this.setState({
        inNetworkCall: false,
        alertType: "danger",
        alertText: refreshAllDataFailedText,
      });
    }
  }

  eventPropGetter = (event, start, end, isSelected) => {
    // default is pending color
    var backgroundColor = pendingEventColor;

    if (event.modalType && event.modalType === "viewSelfEvent") {
      if (event.pairInfo) {
        event.pairInfo.every((pair) => {
          if (pair.pairStatus === "ACCEPTED") {
            // if any pair is accepted status, color is confirmed
            backgroundColor = confirmedEventColor;
            return false;
          }
          return true;
        });
      }
    } else if (event.modalType && event.modalType === "viewWorldEvent") {
      if (event.pairInfo && event.pairInfo[0].pairStatus === "ACCEPTED") {
        backgroundColor = confirmedEventColor;
      }
    }
    var style = {
      backgroundColor: backgroundColor,
    };
    return {
      className: "worldCalendar",
      style: style,
    };
  };

  dayHeaderFormat = (date, culture, local) => {
    const offsetString =
      "(Timezone: UTC" + DateTime.fromJSDate(date).toFormat("Z") + ")";
    return local.format(date, "LLL dd", culture) + " " + offsetString;
  };

  dayRangeHeaderFormat = ({ start, end }, culture, local) => {
    const offsetString =
      "(Timezone: UTC" + DateTime.fromJSDate(start).toFormat("Z") + ")";
    return (
      local.format(start, "LLL dd", culture) +
      " – " +
      local.format(end, "LLL dd", culture) +
      " " +
      offsetString
    );
  };

  async componentDidMount() {
    // fetch all the schedule data
    await this.fetchScheduleData();
  }

  render() {
    const localizer = luxonLocalizer(DateTime, { firstDayOfWeek: 7 });

    var eventsToShow = [];
    if (this.state.whichCalendar === "myCalendar") {
      eventsToShow = [...this.state.myEvents, ...this.state.eventsISentPairsTo];
    } else if (this.state.whichCalendar === "worldCalendar") {
      eventsToShow = this.state.worldGroupEvents;
    }

    return (
      <>
        <Container>
          <Alert
            key="topAlert"
            variant={this.state.alertType}
            className="top-header"
          >
            <Row>
              {this.state.inNetworkCall ? (
                <>
                  <Col className="verticalAlignCol">
                    <div className="leftOfTwo">
                      {"Loading calendar data..."}
                    </div>
                  </Col>

                  <Col className="verticalAlignCol">
                    <Spinner className="rightOfTwo" animation="border" />
                  </Col>
                </>
              ) : (
                <Col className="verticalAlignCol">
                  <div>{this.state.alertText}</div>
                </Col>
              )}
            </Row>
          </Alert>
          <Row>
            <Col className="flexCol">
              <div className="rightOfTwo regularItem">
                <Button
                  className="refreshButton"
                  key={"refreshButton"}
                  id={"refreshButton"}
                  type="radio"
                  variant="outline-primary"
                  name="refreshCalendar"
                  onClick={(e) => this.handleRefresh(e)}
                >
                  Refresh
                </Button>
                <ButtonGroup>
                  <ToggleButton
                    key={"selfCalendar"}
                    id={"selfCalendar"}
                    type="radio"
                    variant="outline-primary"
                    name="selfCalendar"
                    value="My Calendar"
                    checked={this.state.whichCalendar === "myCalendar"}
                    onChange={(e) => this.handleShowMyCalendar(e)}
                  >
                    My Calendar
                  </ToggleButton>
                  <ToggleButton
                    key={"worldCalendar"}
                    id={"worldCalendar"}
                    type="radio"
                    variant="outline-primary"
                    name="worldCalendar"
                    value="World Calendar"
                    checked={this.state.whichCalendar === "worldCalendar"}
                    onChange={(e) => this.handleShowWorldCalendar(e)}
                  >
                    World Calendar
                  </ToggleButton>
                </ButtonGroup>
              </div>
            </Col>
          </Row>
        </Container>

        {this.props.isVisible ? (
          <Calendar
            defaultView={Views.WEEK}
            showMultiDayTimes={true}
            localizer={localizer}
            events={eventsToShow}
            onSelectEvent={this.handleSelectEvent}
            onSelectSlot={this.handleSelectSlot}
            scrollToTime={new Date()}
            formats={{
              dayRangeHeaderFormat: this.dayRangeHeaderFormat,
              dayHeaderFormat: this.dayHeaderFormat,
              ...(this.state.whichCalendar === "worldCalendar"
                ? { eventTimeRangeFormat: () => null }
                : {}),
            }}
            selectable={
              this.state.whichCalendar === "myCalendar" ? true : false
            }
            style={{ height: 500 }}
            views={[Views.WEEK, Views.DAY]}
            eventPropGetter={this.eventPropGetter}
          />
        ) : null}

        <SchedulerModal
          modalType={this.state.modalType}
          hideModalFunction={this.handleHideModal}
          showModal={this.state.showModal}
          formValidated={this.state.formValidated}
          inNetworkCall={this.state.inNetworkCall}
          networkError={this.state.networkErrorSelfEventModal}
          submitFunction={this.handleSubmitSelfScheduleItem}
          handleTitleChange={this.handleTitleChange}
          title={this.state.title}
          handleExtraDetailsChange={this.handleExtraDetailsChange}
          extraDetails={this.state.extraDetails}
          handleAgendaChange={this.handleAgendaChange}
          agenda={this.state.agenda}
          handlePreferredExperienceLevelChange={
            this.handlePreferredExperienceLevelChange
          }
          preferredExperienceLevel={this.state.preferredExperienceLevel}
          handleProgrammingLanguageChange={this.handleProgrammingLanguageChange}
          programmingLanguages={this.state.programmingLanguages}
          eventStatus={this.state.eventStatus}
          sendToDeleteConfirmationModal={this.sendToDeleteConfirmationModal}
          requestJoinEvent={this.requestJoinEvent}
          pairInfo={this.state.pairInfo}
          viewRequests={this.viewRequests}
          alertText={this.state.alertText}
          showUserProfileModal={this.showUserProfileModal}
          meetingId={this.state.meetingId}
          startTime={this.state.startTime}
          endTime={this.state.endTime}
          confirmedEvents={this.state.confirmedEvents}
          isOverLappingWithEvents={this.isOverLappingWithEvents}
        />

        {this.state.groupOfEventsEvent ? (
          <SchedulerGroupEventModal
            showModal={this.state.showEventGroupModal}
            hideModalFunction={this.handleHideEventGroupModal}
            groupOfEventsEvent={this.state.groupOfEventsEvent}
            seeEventDetailsFromGroupModal={this.seeEventDetailsFromGroupModal}
          />
        ) : null}

        <RequestJoinModal
          showModal={this.state.showMessageModal}
          hideModalFunction={this.hideMessageModalFunction}
          submitFunction={this.submitJoinRequest}
          networkError={this.state.networkErrorSelfEventModal}
          formValidated={this.state.formValidated}
          message={this.state.message}
          handleMessageChange={this.handleMessageChange}
          inNetworkCall={this.state.inNetworkCall}
          alertText={this.state.alertText}
        />

        {this.state.pairInfo ? (
          <ViewRequestsModal
            showModal={this.state.showRequestsModal}
            hideModalFunction={this.hideRequestsModalFunction}
            acceptPairFunction={this.handleRequest("acceptPairRequest")}
            rejectPairFunction={this.handleRequest("rejectPairRequest")}
            submitFunction={this.acceptRequest}
            networkError={this.state.networkErrorSelfEventModal}
            inNetworkCall={this.state.inNetworkCall}
            pairInfo={this.state.pairInfo}
            eventTitle={this.state.eventTitle}
            onAccordionSelect={this.onAccordionSelect}
            alertText={this.state.alertText}
            startTime={this.state.startTime}
            showUserProfileModal={this.showUserProfileModal}
          />
        ) : null}

        <Modal
          show={this.state.showUserProfileModal}
          onHide={this.hideUserProfileModal}
          backdrop={"static"}
          animation={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Viewing User Profile</Modal.Title>
          </Modal.Header>
          <UserProfile
            idToken={this.props.idToken}
            viewRestricted={true}
            userId={this.state.userId}
          ></UserProfile>
        </Modal>

        <ConfirmEventDeletionModal
          showDeleteEventConfirmationModal={
            this.state.showDeleteEventConfirmationModal
          }
          hideDeleteConfirmationModal={this.hideDeleteConfirmationModal}
          deleteEvent={this.deleteEvent}
          networkError={this.state.networkErrorSelfEventModal}
          inNetworkCall={this.state.inNetworkCall}
          alertText={this.state.alertText}
          pairInfo={this.state.pairInfo}
        ></ConfirmEventDeletionModal>

        <TutorialModal
          showModal={this.props.showTutorialModal}
          hideModalFunction={this.props.hideTutorialModalFunction}
          onDisableTutorialChecked={this.props.onDisableTutorialChecked}
        ></TutorialModal>
      </>
    );
  }
}

export default Scheduler;
