import React, { Component } from "react";
import { Container } from "react-bootstrap";
import PropTypes from "prop-types";

import Footer from "./Footer";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import { Spinner } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {
  CognitoUserPool,
  CognitoUserAttribute,
  AuthenticationDetails,
  CognitoUser,
} from "amazon-cognito-identity-js";

class Login extends Component {
  constructor(props) {
    super(props);

    const poolData = {
      UserPoolId: this.props.poolId, // Your user pool id here "us-east-1_8PGfKWyPb"
      ClientId: this.props.poolClientId, // Your client id here "3i8v7jt7me8egq4rq7sgsgd9qn"
    };

    this.state = {
      loggedIn: this.props.loggedIn,
      usingLoginFlow: true,
      usingSignupFlowPart1: false, // enter in email and password
      usingSignupFlowPart2: false, // entering the code from email to confirm account
      usingForgotPasswordFlowPart1: false, // entering the email and "reset my password" button
      usingForgotPasswordFlowPart2: false, // entering code from email and new password twice
      userPool: new CognitoUserPool(poolData),
      email: null,
      savedEmail: null,
      code: null,
      password: "",
      password2: "",
      inNetworkCall: false,
      error: false,
      alertType: "primary",
      alertText: this.props.loginMessage,
      cognitoUser: null,
      accessToken: this.props.accessToken,
      idToken: this.props.idToken,
    };

  }

  // text field updates
  handleEmailChange = (event) => {
    this.setState({ email: event.target.value });
  };

  handleCodeChange = (event) => {
    this.setState({ code: event.target.value });
  };

  handlePasswordChange = (event) => {
    this.setState({ password: event.target.value });
  };

  handlePasswordChange2 = (event) => {
    this.setState({ password2: event.target.value });
  };

  // page transitions
  loginToRegisterPage = () => {
    this.setState({
      usingSignupFlowPart1: true,
      usingLoginFlow: false,
      password: "",
      email: "",
      code: "",
    });
  };

  registerToLoginPage = () => {
    this.setState({
      usingSignupFlowPart1: false,
      usingLoginFlow: true,
      password: "",
      password2: "",
      email: "",
      code: "",
      alertType: "primary",
      alertText: "",
    });
  };

  loginToForgotPasswordPage = () => {
    this.setState((prevState) => ({
      usingForgotPasswordFlowPart1: true,
      usingLoginFlow: false,
      password: "",
      email: "",
      code: "",
      alertType: "primary",
      alertText:
        "Enter the email address associated with your account to reset your password.",
    }));
  };

  forgotPasswordToLoginPage = () => {
    this.setState({
      usingForgotPasswordFlowPart1: false,
      usingLoginFlow: true,
      password: "",
      email: "",
      code: "",
      alertType: "primary",
      alertText: "",
    });
  };

  // cognito/user auth updates
  handleLogin = (event) => {
    event.preventDefault();
    this.setState({ inNetworkCall: true }, () => {
      var authenticationData = {
        Username: this.state.email,
        Password: this.state.password,
      };
      var authenticationDetails = new AuthenticationDetails(authenticationData);
      var userData = {
        Username: this.state.email,
        Pool: this.state.userPool,
      };
      var cognitoUser = new CognitoUser(userData);
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (result) => {
          var accessToken = result.getAccessToken().getJwtToken();
          var idToken = result.getIdToken().getJwtToken();

          this.setState({
            inNetworkCall: false,
            usingLoginFlow: false,
            password: "",
            password2: "",
            cognitoUser: cognitoUser,
            accessToken: accessToken,
            idToken: idToken,
            loggedIn: true,
          });

          this.props.updateTokensOnLoginSuccess(accessToken, idToken)
        },

        onFailure: (err) => {
          console.log(err.message || JSON.stringify(err));

          this.setState({
            inNetworkCall: false,
            error: true,
            alertType: "danger",
            alertText: err.message,
          });
          return;
        },
      });
    });
  };

  handleSignUp = (event) => {
    event.preventDefault();
    this.setState({ inNetworkCall: true }, () => {
      this.state.userPool.signUp(
        this.state.email,
        this.state.password,
        null,
        null,
        (err, result) => {
          if (err) {
            console.log(err.message || JSON.stringify(err));

            this.setState({
              inNetworkCall: false,
              error: true,
              alertType: "danger",
              alertText: err.message,
            });
            return;
          }
          var cognitoUser = result.user;
          this.setState({
            inNetworkCall: false,
            usingSignupFlowPart1: false,
            usingSignupFlowPart2: true,
            password: "",
            cognitoUser: cognitoUser,
            alertType: "primary",
            alertText: `A code has been sent to your email address ${this.state.email}.`,
          });
        }
      );
    });
  };

  handleVerifyCode = (event) => {
    event.preventDefault();
    this.setState({ inNetworkCall: true }, () => {
      this.state.cognitoUser.confirmRegistration(
        this.state.code,
        true,
        (err, result) => {
          if (err) {
            console.log(err.message || JSON.stringify(err));

            this.setState({
              inNetworkCall: false,
              error: true,
              alertType: "danger",
              alertText: err.message,
            });
            return;
          }

          this.setState({
            inNetworkCall: false,
            usingSignupFlowPart1: false,
            usingSignupFlowPart2: false,
            code: "",
            usingLoginFlow: true,
            alertType: "primary",
            alertText:
              "New user successfully created. Please login with the new user.",
          });
        }
      );
    });
  };

  handlePasswordReset = (fromSelfPage) => (event) => {
    event.preventDefault();
    this.setState({ inNetworkCall: true }, () => {
      var userData = {
        Username: this.state.email,
        Pool: this.state.userPool,
      };
      var cognitoUser = new CognitoUser(userData);
      cognitoUser.forgotPassword({
        onSuccess: (data) => {
          // successfully initiated reset password request (sent email)
          const alertText1 = `Please check your email ${this.state.email} for the password reset code.`;
          const alertText2 = `New code sent to ${this.state.email}. Please check your email for the password reset code.`;
          var newAlertText = fromSelfPage ? alertText2 : alertText1;
          this.setState({
            inNetworkCall: false,
            usingForgotPasswordFlowPart1: false,
            usingForgotPasswordFlowPart2: true,
            alertType: "primary",
            alertText: newAlertText,
            code: "",
          });
        },
        onFailure: (err) => {
          console.log(err.message || JSON.stringify(err));

          this.setState({
            inNetworkCall: false,
            error: true,
            alertType: "danger",
            alertText: err.message,
          });
          return;
        },
      });
    });
  };

  handlePasswordResetChangePassword = (event) => {
    event.preventDefault();
    this.setState({ inNetworkCall: true }, () => {
      var userData = {
        Username: this.state.email,
        Pool: this.state.userPool,
      };
      var cognitoUser = new CognitoUser(userData);
      cognitoUser.confirmPassword(this.state.code, this.state.password, {
        onSuccess: () => {
          // successfully reset the password
          this.setState({
            inNetworkCall: false,
            usingForgotPasswordFlowPart2: false,
            usingLoginFlow: true,
            code: "",
            password: "",
            password2: "",
            alertType: "primary",
            alertText:
              "Password reset successful. Please use the new password to login.",
          });
        },
        onFailure: (err) => {
          console.log(err.message || JSON.stringify(err));

          this.setState({
            inNetworkCall: false,
            error: true,
            alertType: "danger",
            alertText: err.message,
          });
          return;
        },
      });
    });
  };

  sendNewCode = (event) => {
    event.preventDefault();
    this.setState({ inNetworkCall: true }, () => {
      this.state.cognitoUser.resendConfirmationCode((err, result) => {
        if (err) {
          console.log(err.message || JSON.stringify(err));

          this.setState({
            inNetworkCall: false,
            error: true,
            alertType: "danger",
            alertText: err.message,
          });
          return;
        }

        this.setState({
          inNetworkCall: false,
          alertType: "primary",
          alertText: `New code sent to ${this.state.email}. Please check your email for the new password reset code.`,
        });
      });
    });
  };

  render() {
    var errorAlert = (
      <Alert key="errorAlert" variant="danger" className="top-header">
        <Row>
          <Col className="verticalAlignCol">
            <div>An error occured. Please try again.</div>
          </Col>
        </Row>
      </Alert>
    );

    var isNotLoggedInAlert = (
      <Alert key="isNotLoggedInAlert" variant="primary" className="top-header">
        <Row>
          <Col className="verticalAlignCol">
            <div>
              You need to be logged in to use the scheduler. Please log in or
              create a new account.
            </div>
          </Col>
        </Row>
      </Alert>
    );

    var sentCodeAlert = (
      <Alert key="sentCodeAlert" variant="primary" className="top-header">
        <Row>
          <Col className="verticalAlignCol">
            <div>
              We have sent a code to your email address. Enter it below to
              confirm your account.
            </div>
          </Col>
        </Row>
      </Alert>
    );

    var wrongPasswordAlert = (
      <Alert key="wrongPasswordAlert" variant="danger" className="top-header">
        <Row>
          <Col className="verticalAlignCol">
            <div>Incorrect username or password. Please try again.</div>
          </Col>
        </Row>
      </Alert>
    );

    var forgotPasswordInfo1 = (
      <Alert key="sentCodeAlert" variant="primary" className="top-header">
        <Row>
          <Col className="verticalAlignCol">
            <div>
              Enter your email message below, we will send a message to reset
              your password.
            </div>
          </Col>
        </Row>
      </Alert>
    );

    var forgotPasswordInfo2 = (
      <Alert key="sentCodeAlert" variant="primary" className="top-header">
        <Row>
          <Col className="verticalAlignCol">
            <div>
              We've sent a password reset code to your email. Please enter it
              below.
            </div>
          </Col>
        </Row>
      </Alert>
    );

    var loginForm = (
      <>
        <Form className="login" onSubmit={this.handleLogin}>
          <Form.Group className="mb-3">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              value={this.state.email}
              onChange={this.handleEmailChange}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              value={this.state.password}
              onChange={this.handlePasswordChange}
            />
            <Form.Text>
              <a href="#" onClick={this.loginToForgotPasswordPage}>
                Forgot your password?
              </a>
            </Form.Text>
          </Form.Group>
          <div className="centerButton">
            {this.state.inNetworkCall ? (
              <Row xs="auto">
                <Col className="rightOfTwo">
                  <Button variant="primary" type="submit" disabled>
                    Log In
                  </Button>
                </Col>
                <Col className="leftOfTwo">
                  <Spinner animation="border" />
                </Col>
              </Row>
            ) : (
              <Button
                variant="primary"
                type="submit"
                disabled={!this.state.password || !this.state.email}
              >
                Log In
              </Button>
            )}
            <Form.Group className="mb-3 mt-1">
              <Form.Text>
                Need an account?{" "}
                <a href="#" onClick={this.loginToRegisterPage}>
                  Sign up
                </a>
              </Form.Text>
            </Form.Group>
          </div>
        </Form>
      </>
    );

    var signupFormPart1 = (
      <Form className="login" onSubmit={this.handleSignUp}>
        <Form.Group className="mb-3">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            value={this.state.email}
            onChange={this.handleEmailChange}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Password"
            value={this.state.password}
            onChange={this.handlePasswordChange}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Enter Password Again</Form.Label>
          <Form.Control
            type="password"
            placeholder="Enter password again"
            value={this.state.password2}
            onChange={this.handlePasswordChange2}
          />
        </Form.Group>
        {this.state.password !== this.state.password2 ? (
          <Form.Group className="mb-3">
            <Form.Text
              style={{
                color: "red",
              }}
            >
              Passwords need to match
            </Form.Text>
          </Form.Group>
        ) : null}
        {this.state.password &&
        (this.state.password.length < 8 || this.state.password2.length < 8) ? (
          <Form.Group className="mb-3">
            <Form.Text
              style={{
                color: "red",
              }}
            >
              Password needs to be 8 characters or longer
            </Form.Text>
          </Form.Group>
        ) : null}
        <div className="centerButton">
          {this.state.inNetworkCall ? (
            <Row xs="auto">
              <Col className="rightOfTwo">
                <Button variant="primary" type="submit" disabled>
                  Sign Up
                </Button>
              </Col>
              <Col className="leftOfTwo">
                <Spinner animation="border" />
              </Col>
            </Row>
          ) : (
            <Button
              variant="primary"
              type="submit"
              disabled={
                this.state.password.length < 8 ||
                !this.state.email ||
                this.state.password !== this.state.password2
              }
            >
              Sign Up
            </Button>
          )}
          <Form.Group className="mb-3 mt-1">
            <Form.Text>
              Already have an account?{" "}
              <a href="#" onClick={this.registerToLoginPage}>
                Sign in
              </a>
            </Form.Text>
          </Form.Group>
        </div>
      </Form>
    );

    var signupFormPart2 = (
      <>
        <Form className="login" onSubmit={this.handleVerifyCode}>
          <Form.Group className="mb-3">
            <Form.Label>Verification Code</Form.Label>
            <Form.Control
              type="code"
              placeholder="Enter code here"
              value={this.state.code}
              onChange={this.handleCodeChange}
            />
          </Form.Group>

          <div className="centerButton">
            {this.state.inNetworkCall ? (
              <Row xs="auto">
                <Col className="rightOfTwo">
                  <Button variant="primary" type="submit" disabled>
                    Confirm Account
                  </Button>
                </Col>
                <Col className="leftOfTwo">
                  <Spinner animation="border" />
                </Col>
              </Row>
            ) : (
              <Button variant="primary" type="submit">
                Confirm Account
              </Button>
            )}
            <Form.Group className="mb-3 mt-1">
              <Form.Text>
                Didn't receive a code?{" "}
                <a href="#" onClick={this.sendNewCode}>
                  Send a new code
                </a>
              </Form.Text>
            </Form.Group>
          </div>
        </Form>
      </>
    );

    var forgotPasswordFormPart1 = (
      <>
        <Form className="login" onSubmit={this.handlePasswordReset(false)}>
          <Form.Group className="mb-3">
            <Form.Label>Email Address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              value={this.state.email}
              onChange={this.handleEmailChange}
            />
          </Form.Group>

          <div className="centerButton">
            {this.state.inNetworkCall ? (
              <Row xs="auto">
                <Col className="rightOfTwo">
                  <Button variant="primary" type="submit" disabled>
                    Reset my password
                  </Button>
                </Col>
                <Col className="leftOfTwo">
                  <Spinner animation="border" />
                </Col>
              </Row>
            ) : (
              <Button
                variant="primary"
                type="submit"
                disabled={!this.state.email}
              >
                Reset my password
              </Button>
            )}
            <Form.Group className="mb-3 mt-1">
              <Form.Text>
                Go back to the{" "}
                <a href="#" onClick={this.forgotPasswordToLoginPage}>
                  login page
                </a>
              </Form.Text>
            </Form.Group>
          </div>
        </Form>
      </>
    );

    var forgotPasswordFormPart2 = (
      <>
        <Form
          className="login"
          onSubmit={this.handlePasswordResetChangePassword}
        >
          <Form.Group className="mb-3">
            <Form.Label>Code</Form.Label>
            <Form.Control
              type="code"
              placeholder="Enter code here"
              value={this.state.code}
              onChange={this.handleCodeChange}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>New password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              value={this.state.password}
              onChange={this.handlePasswordChange}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Enter New Password Again</Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter new password again"
              value={this.state.password2}
              onChange={this.handlePasswordChange2}
            />
          </Form.Group>
          {this.state.password !== this.state.password2 ? (
            <Form.Group className="mb-3">
              <Form.Text
                style={{
                  color: "red",
                }}
              >
                Passwords need to match
              </Form.Text>
            </Form.Group>
          ) : null}
          {this.state.password &&
          (this.state.password.length < 8 ||
            this.state.password2.length < 8) ? (
            <Form.Group className="mb-3">
              <Form.Text
                style={{
                  color: "red",
                }}
              >
                Password needs to be 8 characters or longer
              </Form.Text>
            </Form.Group>
          ) : null}

          <div className="centerButton">
            {this.state.inNetworkCall ? (
              <Row xs="auto">
                <Col className="rightOfTwo">
                  <Button variant="primary" type="submit" disabled>
                    Change password
                  </Button>
                </Col>
                <Col className="leftOfTwo">
                  <Spinner animation="border" />
                </Col>
              </Row>
            ) : (
              <Button
                variant="primary"
                type="submit"
                disabled={
                  !this.state.code ||
                  this.state.password !== this.state.password2
                }
              >
                Change password
              </Button>
            )}
            <Form.Group className="mb-3 mt-1">
              <Form.Text>
                Didn't receive a code?{" "}
                <a href="#" onClick={this.handlePasswordReset(true)}>
                  Send a new password reset code
                </a>
              </Form.Text>
            </Form.Group>
          </div>
        </Form>
      </>
    );

    var displayedForm;

    if (this.state.usingLoginFlow) {
      displayedForm = loginForm;
    } else if (this.state.usingSignupFlowPart1) {
      displayedForm = signupFormPart1;
    } else if (this.state.usingSignupFlowPart2) {
      displayedForm = signupFormPart2;
    } else if (this.state.usingForgotPasswordFlowPart1) {
      displayedForm = forgotPasswordFormPart1;
    } else if (this.state.usingForgotPasswordFlowPart2) {
      displayedForm = forgotPasswordFormPart2;
    }

    var displayedAlert = (
      <Alert
        key="topAlert"
        variant={this.state.alertType}
        className="top-header"
      >
        <Row>
          <Col className="verticalAlignCol">
            <div>{this.state.alertText}</div>
          </Col>
        </Row>
      </Alert>
    );

    const childElements = React.Children.map(this.props.children, (child) =>
      React.isValidElement(child)
        ? React.cloneElement(child, {
            accessToken: this.state.accessToken,
            idToken: this.state.idToken,
            cognitoUser: this.state.cognitoUser,
          })
        : child
    );

    return (
      <>
        <Container>
          {this.state.loggedIn ? (
            childElements
          ) : (
            <>
              {this.state.alertText ? displayedAlert : null}
              {displayedForm}
            </>
          )}
        </Container>
      </>
    );
  }
}

Login.propTypes = {
  loginMessage: PropTypes.string.isRequired,
  poolId: PropTypes.string.isRequired,
  poolClientId: PropTypes.string.isRequired,
  accessToken: PropTypes.string.isRequired,
  idToken: PropTypes.string.isRequired,
  updateTokensOnLoginSuccess: PropTypes.func.isRequired,
  loggedIn: PropTypes.bool.isRequired
};

export default Login;
