export const millisToDisableToggle = 3000;
export const notLoggedInMessage =
  "You need to be logged in to see this page. Please log in or create a new account.";
export const loggedOutMessage = "Session timed out. Please log in again."
export const poolId = "us-east-1_8PGfKWyPb";
export const poolClientId = "3i8v7jt7me8egq4rq7sgsgd9qn";
export const programmingLanguages = [
  "Python",
  "Javascript",
  "Java",
  "C#",
  "C",
  "C++",
  "Go",
  "R",
  "Swift",
  "PHP",
  "Kotlin",
  "MATLAB",
  "Perl",
  "Ruby",
  "Rust",
  "Scala",
];

export const EventTypes = {
  SelfEventUnsubmitted: "SelfEventUnsubmitted",
  SelfEventNoPendingPairs: "SelfEventNoPendingPairs",
  SelfEventPendingPairs: "SelfEventPendingPairs",
  WorldEventPendingPair: "WorldEventPendingPair",
  WorldEvent: "WorldEvent",
  PairedEvent: "PairedEvent",
};

const event1 = {
  title: "Test my event 1",
  eventType: EventTypes.SelfEventUnsubmitted,
  start: new Date("2023-01-28T18:00:00"),
  end: new Date("2023-01-28T19:00:00"),
};
const event2 = {
  title: "Test my event nobody signed up yet",
  eventType: EventTypes.SelfEventNoPendingPairs,
  start: new Date("2023-01-30T10:00:00"),
  end: new Date("2023-01-30T11:30:00"),
};
const event3 = {
  title: "Test pending someone signed up my event 1",
  eventType: EventTypes.SelfEventPendingPairs,
  start: new Date("2023-01-29T18:00:00"),
  end: new Date("2023-01-29T19:00:00"),
};
const event4 = {
  title: "Test pending I signed up for someone event",
  eventType: EventTypes.WorldEventPendingPair,
  start: new Date("2023-01-29T12:00:00"),
  end: new Date("2023-01-29T12:30:00"),
};
const event5 = {
  title: "Test world event",
  eventType: EventTypes.WorldEvent,
  start: new Date("2023-01-30T13:00:00"),
  end: new Date("2023-01-30T14:30:00"),
};
const event6 = {
  title: "Test paired event",
  eventType: EventTypes.PairedEvent,
  start: new Date("2023-01-30T20:00:00"),
  end: new Date("2023-01-30T21:30:00"),
};

export const testAllEvents = [event1, event2, event3, event4, event5, event6];
export const selfAlertText =
  "You are currently viewing your calendar. These are events you've created or signed up for.";
export const worldAlertText =
  "You are currently viewing the world calendar. These are events created by other people.";
export const submitSelfEventSuccessText =
  "Successfully created a new event. Click on the event to open the event view. There you can see people's requests to join.";
export const submitSelfEventFailedText = "Creating a new event failed. Please try again."
export const deleteEventSuccessText = "Successfully deleted event.";
export const sendJoinRequestSuccessText = "Successfully sent join request.";
export const sendJoinRequestFailedText = "Send join request failed, please try again."

export const loadingCalendarDataText = "Loading Calendar Data...";
export const submitSucceededReloadingCalendarDataText =
  "Submit new event succeeded, reloading calendar data...";
export const deleteSucceededReloadingCalendarDataText =
  "Delete event succeeded, reloading calendar data...";
export const deleteEventFailedText = "Delete event failed, please try again.";
export const sendJoinRequestSucceededReloadingCalendarDataText =
  "Send join request succeeded, reloading calendar data...";

export const acceptRequestSucceededReloadingCalendarDataText =
  "Accepting request succeeded, reloading calendar data...";
export const acceptRequestSuccessText = "Successfully accepted request.";
export const acceptRequestFailureText = "Error occured when accepting request. Please try again."

export const rejectRequestSucceededReloadingCalendarDataText =
  "Rejecting request succeeded, reloading calendar data...";
export const rejectRequestSuccessText = "Successfully rejected request.";
export const rejectRequestFailureText = "Error occured when rejecting request. Please try again."

export const handleRequestStrings = {
  acceptPairRequest : {
    success: acceptRequestSuccessText,
    failure: acceptRequestFailureText,
    successReloading: acceptRequestSucceededReloadingCalendarDataText
  },
  rejectPairRequest : {
    success: rejectRequestSuccessText,
    failure: rejectRequestFailureText,
    successReloading: rejectRequestSucceededReloadingCalendarDataText
  }
}

export const refreshAllDataFailedText = "An error occured when fetching calendar data. Please try again.";
export const refreshAllDataSuccessText = "Successfully loaded calendar data."

export const experienceLevels = [
  { name: "Beginner (0-2 years)", value: "0" },
  { name: "Intermediate (2-5 years)", value: "1" },
  { name: "Advanced (5+ years)", value: "2" },
];
export const updateMyProfileDataStartedText = "Updating my profile data...";
export const updateMyProfileDataFailedText = "Updating my profile data failed. Try again later."
export const updateMyProfileDataSuccessText = "Updating my profile data succeeded."

export const getMyProfileDataStartedText = "Loading my profile data...";
export const getMyProfileDataSuccessText = "Loading my profile data succeeded."
export const getMyProfileDataFailedText = "Loading my profile data failed. Try again later."

export const getUserProfileDataStartedText = "Loading profile data...";
export const getUserProfileDataSuccessText = "Loading profile data succeeded."
export const getUserProfileDataFailedText = "Loading profile data failed. Try again later."

// api endpoint
export const apiEndpoint = "www.google.com";

export const millisPerHour = 60 * 60 * 1000;
export const eventsPageSize = 5;
export const pairRequestsPageSize = 10;

// event colors
export const confirmedEventColor = "#0e9c34";
export const pendingEventColor = "#d48f04";

// live pair finder scheduler promotion string
export const trySchedulerString = " Instead of waiting, try out the scheduler! You can setup meetings in advance instead of waiting."