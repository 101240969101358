import React, { Component } from "react";
import { Container } from "react-bootstrap";
import cdkOutputObject from "./cdk-outputs.json";

class Info extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const schedulerEnabled = cdkOutputObject.PairBackendStack.schedulerEnabled === "true";
    const schedulerDiv = (
      <div>
        <h1 className="top-header"> Info </h1>
        <p className="default-row-spacer">
          Welcome! This website will help you find pair programming/computer
          science partners.{" "}
        </p>
        <p className="default-row-spacer">
          To start, navigate to the "Scheduler" page and create an
          account. Then create an event. Other people on the site will see your
          event and can send a request to connect. You can also browse events that
          other people created and request to join those.{" "}
        </p>
        <p className="default-row-spacer">
          If you want a partner right now, this website has a live partner
          feature. Navigate to the "On-demand finder" section of the site, select
          your level, and toggle the switch to on. Also, please enable
          notifications. By doing so, the website will notify you once a match
          is found (feel free to switch to other tabs, but don't close this
          tab).{" "}
        </p>
        <p className="default-row-spacer">
          This website does not moderate or record the video calls.{" "}
        </p>
      </div>
    );

    const noSchedulerDiv = (
      <div>
        <h1 className="top-header"> Info </h1>
        <p className="default-row-spacer">
          Welcome! This website will help you find an on-demand pair programming
          partner.{" "}
        </p>
        <p className="default-row-spacer">
          To use this website, navigate to the "On-demand finder" section of the
          site, select your level, and toggle the switch to on. Also,
          please enable notifications. By doing so, the website will notify you
          once a match is found (feel free to switch to other tabs, but don't
          close this tab). Once a match is found, you will have 1 minute to
          accept. Once both people accept, a video call will be created on the
          page. You will be able to change video call settings (yes/no video,
          mute/unmute, your name) before joining the call. The video call allows
          you to share your screen, to facilitate pair programming.{" "}
        </p>
        <p className="default-row-spacer">
          This website does not moderate or record the video calls.{" "}
        </p>
      </div>
    );

    return (
      <>
        <Container>
          {schedulerEnabled ? schedulerDiv : noSchedulerDiv}
        </Container>
      </>
    );
  }
}

export default Info;
