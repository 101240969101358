import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { Component } from "react";
import PropTypes from "prop-types";
import { Container, Spinner } from "react-bootstrap";
import ToggleButton from "react-bootstrap/ToggleButton";
import axios from "axios";
import cdkOutputObject from "./cdk-outputs.json";
import {
  experienceLevels,
  getMyProfileDataFailedText,
  getMyProfileDataStartedText,
  getMyProfileDataSuccessText,
  getUserProfileDataFailedText,
  getUserProfileDataStartedText,
  getUserProfileDataSuccessText,
  updateMyProfileDataFailedText,
  updateMyProfileDataStartedText,
  updateMyProfileDataSuccessText,
} from "./constants";

class UserProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      aboutText: null,
      experienceLevel: null,
      formValidated: false,
      inNetworkCall: false,
      alertType: null,
      alertText: null,
    };
  }

  async fetchUserProfileData(userId) {
    this.setState({
      inNetworkCall: true,
      alertType: "primary",
      alertText: getUserProfileDataStartedText,
    });

    try {
      const profileDataResponse = await axios.get(
        cdkOutputObject.PairBackendStack.schedulerUrl + "userProfile",
        {
          headers: { Authorization: `Bearer ${this.props.idToken}` },
          params: {
            userId: userId,
          },
        }
      );
      const profileData = profileDataResponse["data"];

      this.setState({
        inNetworkCall: false,
        aboutText: profileData["aboutText"],
        experienceLevel: profileData["experienceLevel"],
        alertText: getUserProfileDataSuccessText,
        alertType: "primary",
      });
    } catch (err) {
      console.log(err);
      this.setState({
        inNetworkCall: false,
        alertType: "danger",
        alertText: getUserProfileDataFailedText,
      });
    }
  }

  async fetchMyProfileData() {
    this.setState({
      inNetworkCall: true,
      alertType: "primary",
      alertText: getMyProfileDataStartedText,
    });

    try {
      const myProfileDataResponse = await axios.get(
        cdkOutputObject.PairBackendStack.schedulerUrl + "myUserProfile",
        { headers: { Authorization: `Bearer ${this.props.idToken}` } }
      );
      const myProfileData = myProfileDataResponse["data"];

      this.setState({
        inNetworkCall: false,
        aboutText: myProfileData["aboutText"],
        experienceLevel: myProfileData["experienceLevel"],
        alertText: getMyProfileDataSuccessText,
        alertType: "primary",
      });
    } catch (err) {
      console.log(err);
      this.setState({
        inNetworkCall: false,
        alertType: "danger",
        alertText: getMyProfileDataFailedText,
      });
    }
  }

  submitUserProfileChanges = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === true) {
      this.setState({
        inNetworkCall: true,
        alertType: "primary",
        alertText: updateMyProfileDataStartedText,
      });

      const formObject = {
        aboutText: this.state.aboutText,
        experienceLevel: this.state.experienceLevel,
      };

      try {
        await axios.post(
          cdkOutputObject.PairBackendStack.schedulerUrl + "myUserProfile",
          JSON.stringify(formObject),
          { headers: { Authorization: `Bearer ${this.props.idToken}` } }
        );

        this.setState({
          inNetworkCall: false,
          alertType: "primary",
          alertText: updateMyProfileDataSuccessText,
          formValidated: false,
        });
      } catch (err) {
        console.log(err);
        this.setState({
          inNetworkCall: false,
          alertType: "danger",
          alertText: updateMyProfileDataFailedText,
        });
      }
    } else {
      this.setState({
        formValidated: true,
      });
    }
  };

  async componentDidMount() {
    // fetch given userId profile data if provided in props, otherwise fetch mine
    if (this.props.userId) {
      await this.fetchUserProfileData(this.props.userId);
    } else {
      await this.fetchMyProfileData();
    }
  }

  handleAboutTextChange = (event) => {
    this.setState({ aboutText: event.target.value });
  };

  handleExperienceLevelChange = (event) => {
    this.setState({ experienceLevel: event.target.value });
  };

  render() {
    var bottomButtons = (
      <Button
        className="rowButton"
        variant="primary"
        type="submit"
        disabled={this.state.inNetworkCall}
      >
        Update My Profile
      </Button>
    );

    return (
      <Container>
        {this.state.alertText ? (
          <Alert
            key="topAlert"
            variant={this.state.alertType}
            className="top-header"
          >
            <Row>
              <Col className="verticalAlignCol">
                <div>{this.state.alertText}</div>
              </Col>
            </Row>
          </Alert>
        ) : null}
        <Form
          noValidate
          validated={this.state.formValidated}
          onSubmit={this.submitUserProfileChanges}
        >
          <Form.Group className="mb-3 userProfileFormGroup">
            <Form.Label>{this.props.viewRestricted ? "About" : "About Me"}</Form.Label>
            <Form.Control
            className="userProfileTextArea"
              as="textarea"
              placeholder={this.props.viewRestricted ? "User has not filled out profile yet" : "Describe yourself here. Other people will be able to see this."}
              value={this.state.aboutText}
              onChange={this.handleAboutTextChange}
              disabled={this.props.viewRestricted || this.state.inNetworkCall}
              required
            />
            <Form.Control.Feedback type="invalid">
              About Me is required.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3 userProfileFormGroup">
            <Form.Label>{this.props.viewRestricted ? "Experience Level" : "My Experience Level"}</Form.Label>
            <ButtonGroup className="regularItem userProfileButtonGroup">
              {experienceLevels.map((experienceLevel, idx) => (
                <ToggleButton
                  key={idx}
                  id={`experienceLevel-${idx}`}
                  type="radio"
                  variant="outline-primary"
                  name="experienceLevel"
                  disabled={this.props.viewRestricted || this.state.inNetworkCall}
                  value={experienceLevel.value}
                  checked={this.state.experienceLevel === experienceLevel.value}
                  onChange={(e) => this.handleExperienceLevelChange(e)}
                >
                  {experienceLevel.name}
                </ToggleButton>
              ))}
            </ButtonGroup>
          </Form.Group>

          {this.props.viewRestricted ? null : (
            <div className="centerButton userProfileFormGroup">
              {this.state.inNetworkCall ? (
                <Row xs="auto">
                  <Col className="rightOfTwo">{bottomButtons}</Col>
                  <Col className="leftOfTwo">
                    <Spinner animation="border" />
                  </Col>
                </Row>
              ) : (
                bottomButtons
              )}
            </div>
          )}
        </Form>
      </Container>
    );
  }
}

UserProfile.propTypes = {
  idToken: PropTypes.string,
  viewRestricted: PropTypes.bool,
  userId: PropTypes.string,
};

export default UserProfile;
