import { Component } from "react";
import { Modal } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PropTypes from "prop-types";
import FixRequiredSelect from "./FixRequiredSelect";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Spinner } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";
import {
  experienceLevels,
  millisPerHour,
  programmingLanguages,
} from "./constants";
import BaseSelect from "react-select";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import RequestJoinModal from "./RequestJoinModal";
import ViewRequestsModal from "./ViewRequestsModal";

class SchedulerModal extends Component {
  constructor(props) {
    super(props);
  }

  hasPairsToShow() {
    if (this.props.pairInfo) {
      const hasPairsToShow = this.props.pairInfo.some((pair) => {
        return pair.pairStatus !== "REJECTED";
      });
      return hasPairsToShow;
    } else {
      return false;
    }
  }

  getTimeString(startDate, endDate) {
    const now = Date.now();
    const millisLeft = startDate.getTime() - now;
    if (millisLeft <= 0) {
      if (now < endDate.getTime()) {
        return "This event is currently in progress.";
      } else {
        return "This event is in the past.";
      }
    }

    var deltaSeconds = millisLeft / 1000;
    const days = Math.floor(deltaSeconds / 86400);
    deltaSeconds -= days * 86400;
    const hours = Math.floor(deltaSeconds / 3600);
    deltaSeconds -= hours * 3600;
    const minutes = Math.floor(deltaSeconds / 60);

    const dayString = days != 0 ? `${days} days` : "";
    const hoursString = hours != 0 ? `${hours} hours` : "";
    const minutesString = minutes != 0 ? `${minutes} minutes` : "";

    return `Event starts in ${dayString} ${hoursString} ${minutesString}`;
  }

  isRequestable(date) {
    const millisLeft = date.getTime() - Date.now();
    if (millisLeft <= 0) {
      return {
        buttonMessage: "Event is in the past, cannot request",
        disabled: true,
      };
    } else if (millisLeft <= millisPerHour * 3) {
      return {
        buttonMessage: "Event is starting soon, cannot request",
        disabled: true,
      };
    } else {
      return { buttonMessage: "Request to join this event", disabled: false };
    }
  }

  render() {
    var sortedProgramingLanguages = [...programmingLanguages].sort();
    var sortedProgramingLanguagesMultiselectList =
      sortedProgramingLanguages.map((programmingLanguage) => {
        return { value: programmingLanguage, label: programmingLanguage };
      });
    var selectedProgrammingLanguages = this.props.programmingLanguages.map(
      (programmingLanguage) => {
        return { value: programmingLanguage, label: programmingLanguage };
      }
    );

    var bottomButtons;
    var overlapDisabledAlert = null;

    if (this.props.modalType === "createEvent") {
      bottomButtons = (
        <Button
          className="rowButton"
          variant="primary"
          type="submit"
          disabled={this.props.inNetworkCall}
        >
          Create event
        </Button>
      );
    } else if (this.props.modalType === "viewSelfEvent") {
      if (this.props.eventStatus === "OVERLAP_DISABLED") {
        var overlapText =
          "You are already attending a different event at the same time. Because of that, this event is not visible for people to sign up.";
        overlapDisabledAlert = (
          <Alert key="modalAlert" variant="warning" className="top-header">
            <Row>
              <Col className="verticalAlignCol">
                <div>{overlapText}</div>
              </Col>
            </Row>
          </Alert>
        );
      }
      bottomButtons =
        this.props.eventStatus === "OVERLAP_DISABLED" ? null : (
          <>
            {this.hasPairsToShow() ? (
              <Button
                className="rowButton"
                variant="primary"
                disabled={this.props.inNetworkCall}
                onClick={this.props.viewRequests}
              >
                View requests
              </Button>
            ) : (
              <Button className="rowButton" variant="outline-primary" disabled>
                No requests for this event yet
              </Button>
            )}
            <Button
              className="rowButton"
              variant="danger"
              disabled={this.props.inNetworkCall}
              onClick={this.props.sendToDeleteConfirmationModal}
              name="test"
            >
              Delete event
            </Button>
          </>
        );
    } else if (this.props.modalType === "viewWorldEvent") {
      var requestStatusText;
      var buttonVariant;
      var disabledByConfirmedEvent = false;
      if (this.props.pairInfo) {
        if (this.props.pairInfo[0].pairStatus === "PENDING") {
          requestStatusText = "Request sent";
          buttonVariant = "outline-success";
        } else if (this.props.pairInfo[0].pairStatus === "ACCEPTED") {
          requestStatusText = "Request accepted";
          buttonVariant = "outline-success";
        } else if (this.props.pairInfo[0].pairStatus === "REJECTED") {
          requestStatusText = "Request rejected";
          buttonVariant = "outline-danger";
        } else if (this.props.pairInfo[0].pairStatus === "OVERLAP_DISABLED") {
          requestStatusText = "Request not visible to creator";
          buttonVariant = "outline-warning";

          var overlapText =
            "You are already attending a different event at the same time. Because of that, the event creator cannot see the request you sent.";
          overlapDisabledAlert = (
            <Alert key="modalAlert" variant="warning" className="top-header">
              <Row>
                <Col className="verticalAlignCol">
                  <div>{overlapText}</div>
                </Col>
              </Row>
            </Alert>
          );
        }
      }

      // pairinfo doesnt exist. Check if sign up disabled because overlapping confirmed event
      else {
        const tempEvent = {
          start: this.props.startTime.getTime(),
          end: this.props.endTime.getTime(),
        };
        disabledByConfirmedEvent = this.props.isOverLappingWithEvents(
          tempEvent,
          this.props.confirmedEvents
        );
        overlapDisabledAlert = disabledByConfirmedEvent ? (
          <Alert key="modalAlert" variant="warning" className="top-header">
            <Row>
              <Col className="verticalAlignCol">
                <div>You already have a confirmed event during this time.</div>
              </Col>
            </Row>
          </Alert>
        ) : null;
      }
      const isRequestableObject = this.isRequestable(this.props.startTime);
      bottomButtons =
        (this.props.pairInfo &&
          this.props.pairInfo[0].pairStatus === "OVERLAP_DISABLED") || disabledByConfirmedEvent ? null : (
          <>
            {this.props.pairInfo ? (
              <Button
                className="rowButton"
                variant={buttonVariant}
                disabled={true}
              >
                {requestStatusText}
              </Button>
            ) : (
              <Button
                className="rowButton"
                variant="primary"
                disabled={
                  this.props.inNetworkCall || isRequestableObject.disabled
                }
                onClick={this.props.requestJoinEvent}
              >
                {isRequestableObject.buttonMessage}
              </Button>
            )}
          </>
        );
    }

    return (
      <>
        <Modal
          show={this.props.showModal}
          onHide={this.props.hideModalFunction}
          backdrop={"static"}
          animation={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.props.modalType === "createEvent"
                ? "Create a new event"
                : this.props.title}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body md="auto">
            {this.props.networkError ? (
              <Alert key="modalAlert" variant="danger" className="top-header">
                <Row>
                  <Col className="verticalAlignCol">
                    <div>{this.props.alertText}</div>
                  </Col>
                </Row>
              </Alert>
            ) : null}
            {overlapDisabledAlert}
            <Row>
              <Col>
                <Form
                  noValidate
                  validated={this.props.formValidated}
                  onSubmit={this.props.submitFunction}
                >
                  {this.props.modalType === "createEvent" ? (
                    <>
                      <Form.Group className="mb-3">
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                          required
                          placeholder="Enter title here"
                          value={this.props.title}
                          onChange={this.props.handleTitleChange}
                          maxLength={100}
                        />
                        <Form.Control.Feedback type="invalid">
                          Title cannot be blank.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </>
                  ) : null}

                  {this.props.meetingId ? (
                    <Form.Group className="mb-3">
                      <Form.Label
                        style={{ color: "brown", fontWeight: "bold" }}
                      >
                        {this.getTimeString(
                          this.props.startTime,
                          this.props.endTime
                        )}
                      </Form.Label>
                      <Row>
                        <a
                          href={`https://meet.jit.si/${this.props.meetingId}`}
                          target="_blank"
                        >
                          Link to Event
                        </a>
                      </Row>
                    </Form.Group>
                  ) : null}

                  {this.props.modalType === "viewWorldEvent" ? (
                    <>
                      <Form.Group className="mb-3">
                        <Button
                          onClick={this.props.showUserProfileModal(null)}
                          variant="primary"
                        >
                          View User Profile
                        </Button>
                      </Form.Group>
                    </>
                  ) : null}

                  <Form.Group className="mb-3">
                    <Form.Label>
                      {this.props.modalType === "createEvent"
                        ? "Select Programming Languages (optional)"
                        : "Programming Languages"}
                    </Form.Label>
                    <FixRequiredSelect
                      name="programmingLanguageSelect"
                      options={sortedProgramingLanguagesMultiselectList}
                      isMulti={true}
                      onChange={this.props.handleProgrammingLanguageChange}
                      SelectComponent={BaseSelect}
                      isDisabled={this.props.modalType !== "createEvent"}
                      value={selectedProgrammingLanguages}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Agenda</Form.Label>
                    <Form.Control
                      as="textarea"
                      placeholder={
                        this.props.modalType === "createEvent"
                          ? "Describe what you would like to do here (leetcode, chat, resume writing, etc.)"
                          : null
                      }
                      value={this.props.agenda}
                      onChange={this.props.handleAgendaChange}
                      required
                      disabled={this.props.modalType !== "createEvent"}
                    />
                    <Form.Control.Feedback type="invalid">
                      Agenda is required.
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Extra Details (optional)</Form.Label>
                    <Form.Control
                      as="textarea"
                      placeholder={
                        this.props.modalType === "createEvent"
                          ? "Enter extra details here"
                          : null
                      }
                      value={this.props.extraDetails}
                      onChange={this.props.handleExtraDetailsChange}
                      disabled={this.props.modalType !== "createEvent"}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>
                      Preferred Experience Level of Partner
                    </Form.Label>
                    <ButtonGroup className="regularItem">
                      {experienceLevels.map((preferredExperienceLevel, idx) => (
                        <ToggleButton
                          key={idx}
                          id={`preferredExperienceLevel-${idx}`}
                          type="radio"
                          disabled={this.props.modalType !== "createEvent"}
                          variant="outline-primary"
                          name="preferredExperienceLevel"
                          value={preferredExperienceLevel.value}
                          checked={
                            this.props.preferredExperienceLevel ===
                            preferredExperienceLevel.value
                          }
                          onChange={(e) =>
                            this.props.handlePreferredExperienceLevelChange(e)
                          }
                        >
                          {preferredExperienceLevel.name}
                        </ToggleButton>
                      ))}
                    </ButtonGroup>
                  </Form.Group>

                  <div className="centerButton">
                    {this.props.inNetworkCall ? (
                      <Row xs="auto">
                        <Col className="rightOfTwo">{bottomButtons}</Col>
                        <Col className="leftOfTwo">
                          <Spinner animation="border" />
                        </Col>
                      </Row>
                    ) : (
                      bottomButtons
                    )}
                  </div>
                </Form>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

SchedulerModal.propTypes = {
  modalType: PropTypes.string,
  showSelfScheduler: PropTypes.bool,
  otherPersonEvents: PropTypes.object,
  hideModalFunction: PropTypes.func,
  showModal: PropTypes.bool,
  formValidated: PropTypes.bool,
  inNetworkCall: PropTypes.bool,
  networkError: PropTypes.bool,
  calendarEvent: PropTypes.object,
  submitFunction: PropTypes.func,
  handleTitleChange: PropTypes.func,
  title: PropTypes.string,
  handleExtraDetailsChange: PropTypes.func,
  extraDetails: PropTypes.string,
  handleAgendaChange: PropTypes.func,
  agenda: PropTypes.string,
  handlePreferredExperienceLevelChange: PropTypes.func,
  preferredExperienceLevel: PropTypes.string,
  handleProgrammingLanguageChange: PropTypes.func,
  programmingLanguages: PropTypes.array,
  viewRequests: PropTypes.func,
  sendToDeleteConfirmationModal: PropTypes.func,
  requestJoinEvent: PropTypes.func,
  pairInfo: PropTypes.object,
  eventStatus: PropTypes.string,
  alertText: PropTypes.string,
  eventId: PropTypes.string,
  userId: PropTypes.string,
  showUserProfileModal: PropTypes.func,
  meetingId: PropTypes.string,
  startTime: PropTypes.object,
  endTime: PropTypes.object,
  isOverLappingWithEvents: PropTypes.func,
  confirmedEvents: PropTypes.array
};

export default SchedulerModal;
